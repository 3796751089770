import React from 'react'
import { ThemeIF } from '@src/design/ThemeContext'

interface ArticleProps {
  theme: ThemeIF
}
const Article: React.SFC<ArticleProps> = (props) => {
  const { children, theme } = props

  return (
    <React.Fragment>
      <article className="article">{children}</article>

      {/* --- STYLES --- */}
      <style jsx>{`
        .article {
          font-family: 'Open Sans', 'Roboto', 'Helvetica', 'sans-serif';
          padding: ${theme.space.inset.default};
          margin: 0 auto 30;
        }
        @from-width tablet {
          .article {
            padding: ${`calc(${theme.space.default}) calc(${
              theme.space.default
            } * 2)`};
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @from-width desktop {
          .article {
            padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${
              theme.space.default
            } * 2)`};
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
    </React.Fragment>
  )
}

export default Article

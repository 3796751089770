import React from 'react'
import { graphql } from 'gatsby'
import Article from '@src/components/Article'
import Page from '@src/components/Page'
import { ThemeConsumer } from '@src/design/ThemeContext'
import './LegalPage.css'

const PageTemplate = (props: any) => {
  const {
    data: {
      page,
      // site: {
      //   siteMetadata: { facebook }
      // }
    },
  } = props

  return (
    <ThemeConsumer>
      {(theme) => (
        <Article theme={theme}>
          <Page page={page} theme={theme} />
        </Article>
      )}
    </ThemeConsumer>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageTemplateQuery($basePath: String!, $language: String!) {
    page: markdownRemark(
      frontmatter: { permalink: { eq: $basePath }, language: { eq: $language } }
    ) {
      id
      html
      frontmatter {
        title
      }
    }
    ...FooterFragment
  }
`

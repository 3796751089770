import React from 'react'
import { ThemeIF } from '@src/design/ThemeContext'

interface BodytextProps {
  html: string
  theme: ThemeIF
}

const Bodytext: React.SFC<BodytextProps> = (props) => {
  const { html, theme } = props

  return (
    <React.Fragment>
      <div
        className="markdown-format bodytext"
        dangerouslySetInnerHTML={{ __html: html }}
      />

      <style jsx>{`
        .bodytext {
          animation-name: bodytextEntry;
          animation-duration: ${theme.time.duration.long};

          :global(pre) {
            font-size: 0.85rem;
            color: ${theme.color.neutral.gray.k};
            padding: 0 1em 0;
            border-left: 2px solid ${theme.color.neutral.gray.g};
            margin-bottom: 2rem;
            overflow-x: auto;
          }
          :global(blockquote) {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding: 1em 0.5rem;
            position: relative;
            font-size: ${theme.font.size.default};
            background-color: #f0f0f0;

            :global(p) {
              margin-bottom: 0;
            }

            cite {
              float: right;
              font-weight: 400;
              padding: 0.3rem;
            }
          }

          /* 
          blockquote + p {
            margin-top: 2rem;
          } 
          */

          :global(h2),
          :global(h3),
          :global(h4) {
            font-weight: 700;
            margin: 1.5em 0 1rem;
            color: ${theme.color.neutral.gray.j};

            :global(.anchor) {
              float: initial;
            }
          }

          :global(h2) {
            line-height: ${theme.font.lineHeight.s};
            font-size: ${theme.font.size.l};
          }

          :global(h3) {
            font-size: ${theme.font.size.m};
            line-height: ${theme.font.lineHeight.m};
          }
          :global(h4) {
            font-size: ${theme.font.size.s};
            line-height: ${theme.font.lineHeight.m};
            margin: 1.3rem 0;
          }

          :global(p) {
            font-size: ${theme.font.size.default};
            line-height: ${theme.font.lineHeight.xxl};
            margin: 0 0 1.5rem;
            color: ${theme.color.neutral.gray.j};
          }
          :global(ul) {
            list-style: circle;
            margin: 0 0 1.5rem;
            padding: 0 0 0 2.5rem;
          }
          :global(ol) {
            margin: 0 0 1.5rem;
            padding: 0 0 0 2.5rem;
          }
          :global(li) {
            font-size: ${theme.font.size.xs};
            margin: 0.2rem 0;
            line-height: 1.5;
            font-weight: 400;
            color: ${theme.color.neutral.gray.i};
          }
          :global(a) {
            font-weight: ${theme.font.weight.bold};
            color: ${theme.color.brand.primary};
            text-decoration: underline;
          }
          :global(a.gatsby-resp-image-link) {
            border: 0;
            display: block;
            margin: 2.5rem 0;
            border-radius: ${theme.size.radius.default};
            overflow: hidden;
            border: 1px solid ${theme.line.color};
          }
          :global(code.language-text) {
            background: ${theme.color.neutral.gray.c};
            text-shadow: none;
            color: inherit;
            padding: 0.1rem 0.3rem 0.2rem;
            border-radius: 0.1rem;
          }
        }

        @keyframes bodytextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

export default Bodytext

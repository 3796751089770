import React from 'react'

import Headline from '../Article/Headline'
import Bodytext from '../Article/Bodytext'
import { ThemeIF } from '@src/design/ThemeContext'

interface PageProps {
  page: {
    html: string
    frontmatter: {
      title: string
    }
  }
  theme: ThemeIF
}
const Page: React.SFC<PageProps> = (props) => {
  const {
    page: {
      html,
      frontmatter: { title },
    },
    theme,
  } = props

  return (
    <React.Fragment>
      <header>
        <Headline title={title} theme={theme} />
      </header>
      <Bodytext html={html} theme={theme} />
    </React.Fragment>
  )
}

export default Page
